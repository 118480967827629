/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";




.fast-drop-zone {
  min-height: 300px;
  height: 80%;
  margin-top: 20px;
  border: 2px dotted var(--ion-color-secondary);
  border-radius: 30px;
}

@media only screen and (min-width: 1000px) {
  .fast-drop-zone {
    height: 550px;
  }
}


.fast-content-zone {
  min-height: 300px;
  height: 80%;
}


@media only screen and (min-width: 1000px) {
  .fast-content-zone {
    height: 550px;
  }
}



.file-content-zone {
  height: 100%;
}



.file-drop-zone {
  min-height: 150px;
  height: 80%;
  margin-top: 20px;
  border: 2px dotted var(--ion-color-secondary);
  border-radius: 30px;
}


@media only screen and (min-width: 1000px) {
  .file-drop-zone {
    height: 250px;
  }
}

ion-footer {
  border-top: 0.5px solid #8080805e;
}

.close-icon-modal {
  font-size: 25pt;
}
